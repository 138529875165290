<script>
  import { Bar, mixins } from 'vue-chartjs'

  export default {
    extends: Bar,
    mixins: [mixins.reactiveProp],
    props: {
      chartData: { type: Object, default: () => ({}) },
      options: { type: Object, default: () => ({}) },
    },
    watch: {
      chartData() {
        this.renderChart(this.chartData, this.options)
      },
    },
    mounted() {
      // Overwriting base render method with actual data.
      this.renderChart(this.chartData, this.options)
    },
  }
</script>
